import React from "react";
import { connect } from "react-redux";
import { selectLeadgen } from "../../selectors";
import { Box, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styles from "../../Leadgen.styles";
import Item from "../common/Item";
import Schedule from "./Schedule";
import eventEmitter, {
  types,
} from "../../../../common/components/helpers/eventEmitter";

interface entityRelatedInfoProps {
  entityId: string | undefined;
  entityMainDetails?: any;
  currentContacts?: any;
  currentManagers?: any;
}

const EntityRelatedInfo = (props: entityRelatedInfoProps) => {
  const { entityId, entityMainDetails, currentContacts, currentManagers } =
    props;

  return (
    <Box>
      <Box sx={styles.relatedInfoItem}>
        <Box sx={{ display: "flex", alignItems: "baseline"}}>
          <Typography sx={styles.contentTitle} variant="body2">
            Schedule date:
          </Typography>
          <Schedule schedule={entityMainDetails.schedule} />
        </Box>
        <IconButton
          onClick={() =>
            eventEmitter.emit(types.openScheduleCalendarModal, entityId)
          }
        >
          <EditIcon sx={styles.relInfoEditIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.relatedInfoItem}>
        <Item
          title={"Label:"}
          text={
            entityMainDetails &&
            entityMainDetails.label &&
            entityMainDetails.label.length > 0
              ? entityMainDetails.label[0].name
              : "n/a"
          }
        />
        <IconButton
          onClick={() =>
            eventEmitter.emit(types.openEntityLabelModal, entityId)
          }
        >
          <EditIcon sx={styles.relInfoEditIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.relatedInfoItem}>
        <Item
          title={"Tags:"}
          text={
            entityMainDetails &&
            entityMainDetails.tags &&
            entityMainDetails.tags.length > 0
              ? entityMainDetails.tags.map((tag: any) => `${tag.name} `)
              : "n/a"
          }
        />
        <IconButton
          onClick={() => eventEmitter.emit(types.openEntityTagsModal, entityId)}
        >
          <EditIcon sx={styles.relInfoEditIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.relatedInfoItem}>
        <Item
          title={"Contacts:"}
          text={
            currentContacts && currentContacts.length > 0
              ? currentContacts.map(
                  (contact: any) =>
                    `${contact.last_name} ${contact.first_name}, `
                )
              : "n/a"
          }
        />
        <IconButton
          onClick={() =>
            eventEmitter.emit(types.openEntityContactsModal, entityId)
          }
        >
          <EditIcon sx={styles.relInfoEditIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.relatedInfoItem}>
        <Item
          title={"Asset managers:"}
          text={
            currentManagers && currentManagers.length > 0
              ? currentManagers.map(
                  (manager: any) =>
                    `${manager.last_name} ${manager.first_name}, `
                )
              : "n/a"
          }
        />
        <IconButton
          onClick={() =>
            eventEmitter.emit(types.openEntityManagersModal, entityId)
          }
        >
          <EditIcon sx={styles.relInfoEditIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.relatedInfoItem}>
        <Item
          title={"Responsible user:"}
          text={
            entityMainDetails.responsible_person_id
              ? `${entityMainDetails.responsible_person.name} ${
                  entityMainDetails.responsible_person.surname
                    ? entityMainDetails.responsible_person.surname
                    : ""
                }`
              : "n/a"
          }
        />
        <IconButton
          onClick={() =>
            eventEmitter.emit(types.openEntityResponsibleUserModal, entityId)
          }
        >
          <EditIcon sx={styles.relInfoEditIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

function mapStateToProps(state: any) {
  const { entityMainDetails, currentContacts, currentManagers } =
    selectLeadgen(state);
  return { entityMainDetails, currentContacts, currentManagers };
}

export default connect(mapStateToProps)(EntityRelatedInfo);
