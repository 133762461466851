import {
  CREATE_EDIT_LPS_CONFIG_REQUEST,
  DELETE_LPS_CONFIG_REQUEST,
  RECEIVE_LPS_CONFIGS_REQUEST,
  RUN_PROCESSING_LPS_REQUEST,
  CHECK_LPS_USAGE_REQUEST,
  RESET_CURRENT_CALCULATION_REQUEST,
} from "../../../common/constants/action-constants";
import { call, put, takeLatest, delay } from "redux-saga/effects";
import {
  createEditLPSConfigsError,
  createEditLPSConfigsSuccess,
  deleteLPSConfigError,
  deleteLPSConfigSuccess,
  receiveLPSConfigsError,
  receiveLPSConfigsSuccess,
  finishCountLPSDataSuccess,
  finishCountLPSDataError,
  checkLpsUsageRequest,
  checkLpsUsageSuccess,
  checkLpsUsageError,
  resetCurrentCalculationSuccess,
} from "../actions";
import ApiService from "../../../common/services/api.service";
import AlertService from "../../../common/services/notification.service";
import UserService from "../../../common/services/user.service";

function* createEditLPSConfig(action) {
  try {
    const result = yield call(ApiService.post, "/lps/configs", action.data);
    if (result && result.data.message) {
      yield put(createEditLPSConfigsSuccess(result.data.message));
    } else {
      AlertService.error("SOMETHING WENT WRONG");
    }
  } catch (error) {
    console.log("Error: ", error);
    yield put(createEditLPSConfigsError(error));
  }
}

function* getLPSConfigs() {
  try {
    const lpsConfigs = yield call(ApiService.get, "/lps/configs");
    yield put(receiveLPSConfigsSuccess(lpsConfigs.data));
  } catch (error) {
    yield put(receiveLPSConfigsError(error));
  }
}

function* deleteLPSConfigs(action) {
  try {
    const result = yield call(ApiService.delete, `/lps/configs/${action.id}`);
    if (result && result.data.message) {
      yield put(deleteLPSConfigSuccess(result.data.message));
    }
  } catch (error) {
    console.log("Error: ", error);
    yield put(deleteLPSConfigError(error));
  }
}

function* receiveLPSResult(action) {
  try {
    UserService.setLPCIdent = action.query.ident;
    const result = yield call(ApiService.post, "/lps/rating", action.query);
    if (result.data.success) {
      AlertService.info(result.data.message);
      yield put(checkLpsUsageRequest());
    } else {
      AlertService.error(result.data.message);
    }
  } catch (error) {
    yield put(finishCountLPSDataError(error));
  }
}

function* checkLpsUsage(action) {
  try {
    let result;
    while (!result) {
      result = yield call(ApiService.post, "/lps/check_lps", {ident: action.ident});
      if (action.needAlertFlag) {
        AlertService.info(result.data.message);
        console.log(result.data.message);
      }
      if (result.data.canUse) {
        if (result.data.lpc) {
          AlertService.success(result.data.message);
          UserService.delLPCIdent();
          UserService.setLPC = JSON.stringify(result.data.lpc);
          yield put(finishCountLPSDataSuccess(result.data.lpc));
        }
        break;
      } else {
        if (result.data.timeout) {
          yield delay(result.data.timeout);
          yield put(checkLpsUsageRequest());
        }
      }
      yield put(checkLpsUsageSuccess(result.data.message));
    }
  } catch (error) {
    yield put(checkLpsUsageError(error));
  }
}

function* resetCurrentCalculation (action) {
  try {
    const result = yield call(ApiService.post, "/lps/reset_calculation", {ident: action.ident});
    if (result) {
      yield put(resetCurrentCalculationSuccess(result.data));
    }
  } catch (error) {
      console.log("Error: ", error);
    }
  }


export default function* actionLPS() {
  yield takeLatest(CREATE_EDIT_LPS_CONFIG_REQUEST, createEditLPSConfig);
  yield takeLatest(RECEIVE_LPS_CONFIGS_REQUEST, getLPSConfigs);
  yield takeLatest(DELETE_LPS_CONFIG_REQUEST, deleteLPSConfigs);
  yield takeLatest(RUN_PROCESSING_LPS_REQUEST, receiveLPSResult);
  yield takeLatest(CHECK_LPS_USAGE_REQUEST, checkLpsUsage);
  yield takeLatest(RESET_CURRENT_CALCULATION_REQUEST, resetCurrentCalculation)

}
