import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "../GlobalSearch.styles";
import { selectSearch } from "../selectors";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { receiveCityRequest } from "../actions/search";
import StyledTextField from '../../../common/components/styled-components/StyledTextField';

const FilterMenu = ({
  settings,
  handleUpdateSettings,
  handleChecked,
  citiesResult,
  changeCities,
  changeLimit,
}) => {
  const {
    queryCity,
    queryCitiesGroup,
    search_limits,
    area_filters,
    search_type,
    entity_search_type,
    entity_type,
    entity_role,
    object_search_type,
    object_plot_params,
  } = settings;

  const convertedQueryCitiesGroup = queryCitiesGroup.map((item) => {
    return { city: item };
  });
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [optionsForCitiesGroup, setOptionsForCitiesGroup] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [inputMultipleValue, setInputMultipleValue] = React.useState("");
  const [value, setValue] = React.useState(queryCity);
  const [multipleValue, setMultipleValue] = useState(convertedQueryCitiesGroup);

  useEffect(() => {
    let active = true;
    let newMultipleOptions: any = [];

    if (inputMultipleValue === "") {
      setOptionsForCitiesGroup(multipleValue ? [...multipleValue] : []);
      return undefined;
    }
    if (active) {
      if (multipleValue) {
        newMultipleOptions = multipleValue;
      }
      if (citiesResult) {
        newMultipleOptions = [...newMultipleOptions, ...citiesResult];
      }
      setOptionsForCitiesGroup(newMultipleOptions);
    }

    return () => {
      active = false;
    };
  }, [dispatch, citiesResult, multipleValue, inputMultipleValue]);

  useEffect(() => {
    let active = true;
    let newOptions: any = [];
    // if (!loading) {
    //   return undefined;
    // }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (active) {
      if (value) {
        newOptions = [value];
      }

      if (citiesResult) {
        newOptions = [...newOptions, ...citiesResult];
      }

      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [dispatch, citiesResult, value, inputValue]);

  useEffect(() => {
    if (!open2 && value !== null) {
      setOptions([]);
    }
  }, [open2, value]);

  useEffect(() => {
    if (!open && multipleValue !== null) {
      setOptionsForCitiesGroup([]);
    }
  }, [open, multipleValue]);

  useEffect(() => {
    changeCities("queryCitiesGroup", multipleValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleValue]);

  useEffect(() => {
    changeCities("queryCity", value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 2) {
      const requestData = {
        inputValue: newInputValue,
      };
      dispatch(receiveCityRequest(requestData));
    }
  };
  const shallowEqualityCheck = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  };

  const handleMultipleChange = (newInputMultipleValue) => {
    setInputMultipleValue(newInputMultipleValue);

    if (newInputMultipleValue.length >= 2) {
      const requestData = {
        inputValue: newInputMultipleValue,
      };
      dispatch(receiveCityRequest(requestData));
    }
  };
  const comparingOptionAndValue = (option, value) => {
    if (Array.isArray(option)) {
      return option.some((item) => shallowEqualityCheck(item, value));
    } else {
      return option === value;
    }
  };

  return (
    <Box sx={styles.filterBlockHolder}>
      <Typography
        variant="h4"
        sx={{ margin: "0 0 10px 10px", fontSize: "20px", color: "#495057" }}
      >
        Filters
      </Typography>
      <Box sx={styles.filtersHolder}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Search limits
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTextField
              fullWidth
              label="Objects limit"
              margin="dense"
              type="number"
              value = {search_limits ? search_limits.objectsLimit : ''}
              variant="standard"
              onChange={(e) => changeLimit("search_limits", "objectsLimit", e)}
            />
            <StyledTextField
              fullWidth
              label="Entities limit"
              margin="dense"
              type="number"
              value = {search_limits ? search_limits.entitiesLimit : ''}
              variant="standard"
              onChange={(e) => changeLimit("search_limits", "entitiesLimit", e)}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Area filters
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControlLabel
              control={
                <Checkbox
                  checked={area_filters.cities}
                  onChange={(e) => handleChecked("area_filters", "cities", e)}
                />
              }
              label={
                <Typography sx={styles.checkBoxLabel}>City groups</Typography>
              }
            />
            <Autocomplete
              disabled={!area_filters.cities}
              id="tags-standard"
              sx={{ marginBottom: "15px" }}
              size="small"
              multiple
              open={open && inputMultipleValue.length >= 2}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              options={optionsForCitiesGroup}
              // loading={true}
              filterOptions={(x) => x}
              autoComplete
              value={multipleValue}
              includeInputInList
              filterSelectedOptions
              blurOnSelect
              fullWidth
              getOptionLabel={(option) => {
                return option.city;
              }}
              isOptionEqualToValue={(option, value) => {
                return comparingOptionAndValue(option, value);
              }}
              onChange={(e, newValue, reason) => {
                // @ts-ignore
                const cities = new Set(
                  multipleValue.map((multiple) => multiple.city)
                );
                setOptionsForCitiesGroup(
                  newValue
                    ? [newValue, ...optionsForCitiesGroup]
                    : optionsForCitiesGroup
                );

                // @ts-ignore
                setMultipleValue([
                  ...multipleValue,
                  ...newValue.filter((o) => !cities.has(o.city)),
                ]);

                if (reason === "removeOption" || reason === "clear") {
                  // @ts-ignore
                  setMultipleValue([...newValue]);
                }
              }}
              onInputChange={(e, newInputMultipleValue) =>
                handleMultipleChange(newInputMultipleValue)
              }
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="City groups" />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={area_filters.city}
                  onChange={(e) => handleChecked("area_filters", "city", e)}
                />
              }
              label={<Typography sx={styles.checkBoxLabel}>City</Typography>}
            />
            <Autocomplete
              disabled={!area_filters.city}
              sx={{ marginBottom: "5px" }}
              size="small"
              open={open2 && inputValue.length >= 2}
              onOpen={() => {
                setOpen2(true);
              }}
              onClose={() => {
                setOpen2(false);
              }}
              options={options}
              // loading={true}
              filterOptions={(x) => x}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              blurOnSelect
              fullWidth
              getOptionLabel={(option) => (option.city ? option.city : option)}
              onChange={(e, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
              }}
              onInputChange={(e, newInputValue) => handleChange(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Default city"
                />
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Search type
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={styles.textField}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_type.entity}
                    onChange={(e) => handleChecked("search_type", "entity", e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>entity</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_type.object}
                    onChange={(e) => handleChecked("search_type", "object", e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>object</Typography>
                }
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Entity search
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={styles.textField}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entity_search_type.entity_name}
                    onChange={(e) =>
                      handleChecked("entity_search_type", "entity_name", e)
                    }
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>by name</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entity_search_type.entity_phone_number}
                    onChange={(e) =>
                      handleChecked(
                        "entity_search_type",
                        "entity_phone_number",
                        e
                      )
                    }
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    by phone number
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entity_search_type.entity_kadaster_id}
                    onChange={(e) =>
                      handleChecked(
                        "entity_search_type",
                        "entity_kadaster_id",
                        e
                      )
                    }
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    by kadaster id
                  </Typography>
                }
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Entity type
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={styles.textField}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entity_type.person}
                    onChange={(e) => handleChecked("entity_type", "person", e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>person</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entity_type.company}
                    onChange={(e) => handleChecked("entity_type", "company", e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>company</Typography>
                }
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Entity role
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={styles.textField}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={entity_type.company === true}
                    checked={entity_role.owner}
                    onChange={(e) => handleChecked("entity_role", "owner", e)}
                  />
                }
                label={<Typography sx={styles.checkBoxLabel}>owner</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={entity_type.company === true}
                    checked={entity_role.non_owner}
                    onChange={(e) =>
                      handleChecked("entity_role", "non_owner", e)
                    }
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>non owner</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={entity_type.company === true}
                    checked={entity_role.contact}
                    onChange={(e) => handleChecked("entity_role", "contact", e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>contact</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={entity_type.company === true}
                    checked={entity_role.manager}
                    onChange={(e) => handleChecked("entity_role", "manager", e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>manager</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={entity_type.company === true}
                    checked={entity_role.all}
                    onChange={(e) => handleChecked("entity_role", "all", e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>all roles</Typography>
                }
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Object search
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={styles.textField}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={object_search_type.object_street_name}
                    onChange={(e) =>
                      handleChecked(
                        "object_search_type",
                        "object_street_name",
                        e
                      )
                    }
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    by street name
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={object_search_type.object_kadaster_id}
                    onChange={(e) =>
                      handleChecked(
                        "object_search_type",
                        "object_kadaster_id",
                        e
                      )
                    }
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    by kadaster id
                  </Typography>
                }
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="legend" sx={styles.textField}>
              Object plot index
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={styles.textField}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={object_plot_params.object_plot_G0}
                    onChange={(e) =>
                      handleChecked("object_plot_params", "object_plot_G0", e)
                    }
                  />
                }
                label={<Typography sx={styles.checkBoxLabel}>G0</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={object_plot_params.object_plot_A1}
                    onChange={(e) =>
                      handleChecked("object_plot_params", "object_plot_A1", e)
                    }
                  />
                }
                label={<Typography sx={styles.checkBoxLabel}>A</Typography>}
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="contained"
          sx={styles.buttonSave}
          // onClick={props.closeModal}
          onClick={handleUpdateSettings}
        >
          Save configuration
        </Button>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  // @ts-ignore
  const { citiesResult } = selectSearch(state);
  return { citiesResult };
}

export default connect(mapStateToProps)(FilterMenu);
