import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { IdleTimerProvider } from "react-idle-timer";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./modules/dashboard/Dashboard";
import ImportPDF from "./modules/importPDF/ImportPDF";
import Admin from "./modules/admin/Admin";
import { QueryObjects } from "./modules/query-objects/QueryObjects";
import Login from "./modules/login/Login";
import LeadgenNoData from "./modules/leadgen/components/leadgen-no-data/LeadgenNoData";
import Leadgen from "./modules/leadgen/Leadgen";
import AddNewEntity from "./modules/add-new-entity/AddNewEntity";
import Leads from "./modules/leads/Leads";
import LPSForm from "./modules/lps-form/LPSForm";
import NotFoundPage from "./containers/not-found-page/404";
import Layout from "./containers/main-layout/Layout";
import SuperAdmin from "./modules/super-admin/SuperAdmin";

import LoginLayout from "./containers/login-layout/LoginLayout";

import UserService from "./common/services/user.service";

import { getCurrentUserRequest } from "./modules/login/actions";

import "./App.styles";
import Modals from "./common/components/modal/containers/Modals";
import theme from "./App.styles";
import { CssBaseline } from "@mui/material";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!UserService.token && window.location.pathname !== "/login") {
      navigate("/login");
    }

    if (
      localStorage.getItem("token") &&
      (window.location.pathname === "/login" ||
        window.location.pathname === "/")
    ) {
      navigate("/dashboard");
    }

    if (localStorage.getItem("token")) {
      dispatch(getCurrentUserRequest(navigate));
    }
  }, [dispatch, navigate]);

  const minutes = 1000 * 60 * 60; // 60 minutes to logout;
  // const minutes = 40000; // 6 minutes to logout;
  const onIdle = () => {
    console.log("Logout Time ", Date());
    UserService.logout();
    navigate("/login", { state: <Login /> });
  };

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ToastContainer autoClose={2000} />
        <IdleTimerProvider timeout={minutes} onIdle={onIdle}>
          <Routes>
            <Route path="/login" element={<LoginLayout />} />
            <Route path="/" element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/import-pdf" element={<ImportPDF />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/query-objects" element={<QueryObjects />} />
              <Route path="/leadgen" element={<LeadgenNoData />} />
              <Route path="/leadgen/:model/:id" element={<Leadgen />} />
              <Route path="/add-new" element={<AddNewEntity />} />
              <Route path="/leads" element={<Leads />} />
              <Route path="/lps-form" element={<LPSForm />} />
              <Route path="/super-admin" element={<SuperAdmin />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </IdleTimerProvider>
      </ThemeProvider>
      <Modals />
    </>
  );
};

export default App;
