import { useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Stack, Typography, IconButton, IconButtonProps, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  receiveMainEntityDetailsRequest,
  setEntityDelete
} from '../../actions/entityDetails';
import { selectLeadgen } from '../../selectors';
import UserAvatar from '../../../../common/components/avatar/UserAvatar';
import { propsTypes } from '../../../../common/constants/field-constants';
import * as React from 'react';
import styles from '../../Leadgen.styles';
import Item from '../common/Item';
import { formatBirthDate } from '../../../../common/formats/date-fromatter';
import eventEmitter, {
  types
} from '../../../../common/components/helpers/eventEmitter';

interface EntityCardProps {
  entityId: string | undefined;
  entityMainDetails?: any;
  isDeleteEntity?: boolean;
  width?: number | undefined;
  height?: number | undefined;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)'
      }
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)'
      }
    }
  ]
}));
const EntityInfoCard = (props: EntityCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { entityId, entityMainDetails, isDeleteEntity, width, height } = props;
  const [entityDetails, setEntityDetails] = useState({} as any);
  const [expanded, setExpanded] = React.useState(false);

  let currentEntityUrl = '';
  let displayingEntityUrl = '';
  const httpVariable = 'http://';
  const httpsVariable = 'https://';
  const ftpVariable = 'ftp://';

  if (entityDetails.url && entityDetails.url.includes(httpVariable)) {
    currentEntityUrl = entityDetails.url;
  } else if (entityDetails.url && entityDetails.url.includes(httpsVariable)) {
    currentEntityUrl = entityDetails.url;
  } else if (entityDetails.url && entityDetails.url.includes(ftpVariable)) {
    currentEntityUrl = entityDetails.url;
  } else if (!entityDetails.url) {
    currentEntityUrl = '';
  } else {
    currentEntityUrl =
      `${httpsVariable}${entityDetails.url}` ||
      `${httpVariable}${entityDetails.url}` ||
      `${ftpVariable}${entityDetails.url}`;
  }

  if (currentEntityUrl && currentEntityUrl.length > 25) {
    displayingEntityUrl = `${entityDetails.url.substr(0, 15)}...`;
  } else if (!(currentEntityUrl && entityDetails.url)) {
    displayingEntityUrl = 'link to...';
  }

  useEffect(() => {
    dispatch(receiveMainEntityDetailsRequest(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    entityMainDetails && setEntityDetails(entityMainDetails);
  }, [dispatch, entityMainDetails]);

  useEffect(() => {
    if (isDeleteEntity) {
      navigate('/leadgen');
      dispatch(setEntityDelete());
    }
  }, [dispatch, navigate, isDeleteEntity]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    // <Card sx={ (width && width <= 1024) && (height && height <= 500) ? [styles.container, styles.containerFullHeight] : styles.container}>
    <Card sx={styles.container}>
      <Box sx={styles.mainInfoHolder}>
        <Stack sx={{ marginRight: '8px' }}>
          <UserAvatar stylesType={propsTypes.LEADGEN} />
        </Stack>
        <Box sx={styles.nameHolder}>
          <Typography sx={[styles.contentTitle, styles.contentTitleInfoCard]} variant="h3">
            {entityDetails.first_name} {entityDetails.last_name}, {entityDetails.age} y.o.
          </Typography>
          {/*<Typography sx={styles.contentTitle} variant="h3">*/}
          {/*  Surname: {entityDetails.last_name}*/}
          {/*</Typography>*/}
          {/*<Typography sx={styles.contentTitle} variant="h3">*/}
          {/*  Age: {entityDetails.age}*/}
          {/*</Typography>*/}
        </Box>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={styles.wrapper}>
          <Box sx={styles.infoHolder}>
            <Item
              title={'Prefix:'}
              text={entityDetails.prefix ? entityDetails.prefix : 'n/a'}
            />
            <Item
              title={'Initials:'}
              text={entityDetails.initials ? entityDetails.initials : 'n/a'}
            />
            <Item
              title={'All names:'}
              text={entityDetails.all_names ? entityDetails.all_names : 'n/a'}
            />
            <Item
              title={'Nick name:'}
              text={entityDetails.nick_name ? entityDetails.nick_name : 'n/a'}
            />
            <Item
              title={'Gender:'}
              text={
                entityDetails.gender
                  ? entityDetails.gender === 'V'
                    ? 'female'
                    : 'male'
                  : 'n/a'
              }
            />
            <Item
              title={'Alive:'}
              text={entityDetails.is_dead === false ? 'yes' : 'no'}
            />
            <Item
              title={'Blacklisted:'}
              text={
                entityDetails.blacklisted === undefined || null
                  ? 'n/a'
                  : entityDetails.blacklisted
                    ? 'yes'
                    : 'no'
              }
            />
          </Box>
          <Box>
            <Item
              title={'D.o.B.:'}
              text={
                entityDetails.birth_date
                  ? formatBirthDate(entityDetails.birth_date)
                  : 'n/a'
              }
            />
            <Item
              title={'Y.o.B.:'}
              text={entityDetails.birth_year ? entityDetails.birth_year : 'n/a'}
            />
            <Item
              title={'P.o.B.:'}
              text={entityDetails.birth_place ? entityDetails.birth_place : 'n/a'}
            />
            <Item
              title={'K.P.N.:'}
              text={
                entityDetails.kadaster_personal_number
                  ? entityDetails.kadaster_personal_number
                  : 'n/a'
              }
            />
            <Item
              title={'Kad.link:'}
              text={
                entityDetails.kadaster_url ? (
                  <a
                    href={entityDetails.kadaster_url || '/'}
                    title={'Kadaster link'}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                  >
                    link to...
                  </a>
                ) : (
                  'n/a'
                )
              }
            />
            <Item
              title={'Kad.objects:'}
              text={
                entityDetails.kadaster_objects_amount
                  ? entityDetails.kadaster_objects_amount
                  : 'n/a'
              }
            />
            <Item
              title={'Link:'}
              text={
                <a
                  href={currentEntityUrl || '/'}
                  title={'Link'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.link}
                >
                  {displayingEntityUrl || entityDetails.url}
                </a>
              }
            />
          </Box>
        </Box>
        <Divider sx={styles.divider} />
        <Typography sx={styles.contentBlockTitle} variant="body2">
          {'Address'}
        </Typography>
        {entityDetails.addresses && entityDetails.addresses.length > 0 ? (
          entityDetails.addresses.map((address: any, index: number) => (
            <Box sx={styles.wrapper} key={index}>
              <Box sx={styles.infoHolder}>
                <Item
                  title={'Type:'}
                  text={address.type ? address.type : 'n/a'}
                />
                <Item
                  title={'Street:'}
                  text={address.street_name ? address.street_name : 'n/a'}
                />
              </Box>
              <Box>
                <Item
                  title={'City:'}
                  text={address.city_name ? address.city_name : 'n/a'}
                />
                <Item
                  title={'Postcode:'}
                  text={address.postcode ? address.postcode : 'n/a'}
                />
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={styles.contentSubTitle} variant="body2">
            {'n/a'}
          </Typography>
        )}
        <Divider sx={styles.divider} />
        <Typography sx={styles.contentBlockTitle} variant="body2">
          {'Phone numbers'}
        </Typography>
        {entityDetails.phone_numbers && entityDetails.phone_numbers.length > 0 ? (
          entityDetails.phone_numbers.map((phone: any, index: number) => (
            <Box sx={styles.wrapper} key={index}>
              <Box sx={styles.infoHolder}>
                <Item title={'Type:'} text={phone.type ? phone.type : 'n/a'} />
              </Box>
              <Box sx={styles.infoHolder}>
                <Item
                  title={'Number:'}
                  text={phone.number ? phone.number : 'n/a'}
                />
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={styles.contentSubTitle} variant="body2">
            {'n/a'}
          </Typography>
        )}
        <Divider sx={styles.divider} />
        <Typography sx={styles.contentBlockTitle} variant="body2">
          {'Emails'}
        </Typography>
        {entityDetails.emails && entityDetails.emails.length > 0 ? (
          entityDetails.emails.map((mail: any, index: number) => (
            <Box sx={styles.wrapper} key={index}>
              <Box sx={styles.infoHolder}>
                <Item title={'Type:'} text={mail.type ? mail.type : 'n/a'} />
              </Box>
              <Box sx={styles.infoHolder}>
                <Item title={'Email:'} text={mail.email ? mail.email : 'n/a'} />
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={styles.contentSubTitle} variant="body2">
            {'n/a'}
          </Typography>
        )}
        <Divider sx={styles.divider} />
        <Typography sx={styles.contentBlockTitle} variant="body2">
          {'Socials'}
        </Typography>
        {entityDetails.socials && entityDetails.socials.length > 0 ? (
          entityDetails.socials.map((social: any, index: number) => (
            <Box sx={styles.wrapper} key={index}>
              <Box sx={styles.infoHolder}>
                <Item
                  title={
                    <a
                      href={social.url}
                      title={social.url || ''}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={styles.link}
                    >
                      {social.type ? social.type.toUpperCase() : social.url}
                    </a>
                  }
                  text={social.url ? '' : 'n/a'}
                />
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={styles.contentSubTitle} variant="body2">
            {'n/a'}
          </Typography>
        )}
        <Box sx={styles.buttonHolder}>
          <Button
            variant="contained"
            sx={styles.buttonEdit}
            onClick={() =>
              eventEmitter.emit(
                types.openEntityObjectEditModal,
                'entityEdit',
                entityId
              )
            }
          >
            Edit
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonDelete}
            onClick={() =>
              eventEmitter.emit(
                types.openDeleteEntityObjectModal,
                'entityDelete',
                entityId
              )
            }
          >
            Delete
          </Button>
        </Box>
      </Collapse>
    </Card>
  );
};

function mapStateToProps(state: any) {
  const { entityMainDetails, isDeleteEntity } = selectLeadgen(state);
  return { entityMainDetails, isDeleteEntity };
}

export default connect(mapStateToProps)(EntityInfoCard);
