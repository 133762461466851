const styles = {
  dataBox: {
    flexGrow: 1,
    height: {
      xs: "30px",
      sm: "40px",
      md: "50px",
      lg: "60px",
    },
    background: "#114B76",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
  },

  toolBar: {
    display: "flex",
    width: "100%",
    minHeight: {
      xs: "26px",
      sm: "40px",
      md: "50px",
      lg: "64px",
    },
  },

  headerContent: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: {
      xs: "10px",
      sm: "10px",
      md: "12px",
      lg: "16px",
    },
    color: "#FFF",
  },

  labelsBox: {
    marginRight: "3rem",
  },

  container: {
    display: {
      xs: "block",
      lg: "flex",
    },
    padding: "1rem 1.5rem",
    alignItems:{
      xs: "center",
      lg:"flex-start"
    } ,

  },

  formHolder: {
    overflowY: "auto",
    // overflowY: "hidden",
    minHeight: "80vh",
    maxHeight: "80vh",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },

  wrapperGoogleDrive:{
    display: 'flex',
    alignItems:"center",
    width: '100%',
    marginBottom:{
      xs: "1.5rem",
      lg: 0,
    },
  },

  googleDrive: {
    whiteSpace: {
      xs: "wrap",
      sm:"nowrap"},
    textTransform: "none",
    marginRight: "1.5rem",

    fontSize: {
      xs: "10px",
      md: "12px",
      // lg: '12px',
      xl: "14px",
    },
    color: "#495057",
  },

  formControl: {
    width: {
      xs: "100%",
      lg: "24ch",
      xl: "28ch",
    },
    marginRight: {
      xs: ".5rem",
      sm: "1rem",
      lg:'2rem' },
  },

  iconButtonStyles:{
    padding: {
      xs: "4px",
      sm: "8px",
    }
  },

  iconColor: {
    color: "#B4CEE5",
    fontSize: {
      xs: "18px",
      sm: "24px",
    },
  },

  containerLabels: {
    overflowY: "auto",
    height: "68vh",

    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },

  listContainer: {
     padding: "1rem",

    // "&::-webkit-scrollbar": {
    //   width: "6px",
    //   backgroundColor: "#f0f0f0",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#ccc",
    //   borderRadius: "4px",
    // },
  },

  listItem: {
    paddingRight: "4.5rem",
    "& .MuiTypography-root":{
      maxWidth: "25rem",
      fontSize: {
        xs: "14px",
        xl: "16px"
      },
    }

  },

  buttonCreate: {
    // maxWidth: "11rem",
    // backgroundColor: "#B4CEE5",
    width: "135px",
    height: "40px",
    background: "rgba(4, 98, 54, 0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(4, 98, 54, 0.9)",
      borderColor: "#046236",
      boxShadow: "none",
    },
  },

  iconList: {
    color: "#B4CEE5",
    cursor: "pointer",
    fontSize: {
      xs: "18px",
      sm: "24px",
    },
  },
};
export default styles;
