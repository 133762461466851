import theme from '../../App.styles';

const styles = {
  layoutWrapper: {
    display: "flex",
    minHeight: "100vh",
    // minWidth: "100vw",
    // width: "100%",
    height: "100%",
    backgroundColor: "#F8F8FB",
    // backgroundColor: "red",

  },
  main: {
    width: "100%",
  },
};

export default styles;
