const styles = {
  card: {
    // minHeight: '27vh',
    padding: '5px 15px'
  },
  objectOwnersCard: {
    minHeight: '27vh',
    marginTop: '15px',
    padding: '5px 15px'
  },
  holder: {
    minHeight: { lg: '87vh' },
    maxHeight: { lg: '87vh' }
  },

  container: {
    // maxHeight: '50vh',
    padding: '5px 15px',
    display: {
      // xs: 'block',
      // sm: 'flex'
      xs: 'flex'
    },
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0 auto',
    // marginBottom: '1rem',
    overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }
  },
  containerMap: {
    minHeight: '87vh',
    // maxHeight: '90vh',
    padding: '5px 15px',
    maxHeight: '87vh',
    height: '100%'
  },
  containerFullHeight: {
    maxHeight: '87vh'
    // height: '100%'
  },
  objectsContainer: {
    // height: '50vh',
    padding: '5px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0 auto',
    overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }
  },
  objectsContainerFullHeight: {
    // height: '75vh'
  },
  cardStakeholders: {
    padding: '5px 15px'
    // marginBottom: '1rem'
  },

  mainInfoHolder: {
    display: 'flex',
    height: '3.5rem',
    alignItems: 'center'
    // marginBottom: '1rem'
  },
  objectInfoHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '17px',
    marginBottom: '15px',
    width: '100%'
  },
  contentBlockTitle: {
    marginBottom: '10px',
    fontSize: {
      xs: '10px',
      md: '12px',
      xl: '14px'
    },
    lineHeight: '0.65em',
    fontWeight: '700',
    fontFamily: 'typography.fontFamily',
    color: '#495057'
  },
  contentTitleMain: {
    paddingTop: '0.8rem',
    fontSize: {
      xs: '12px',
      md: '14px',
      xl: '16px'
    },
    lineHeight: '0.65em',
    fontWeight: '700',
    fontFamily: 'typography.fontFamily',
    color: '#495057'
  },

  objectContentTitle: {
    fontSize: {
      xs: '12px',
      md: '14px',
      lg: '16px'
    },
    // lineHeight: '0.65em',
    fontWeight: '700',
    fontFamily: 'typography.fontFamily',
    color: '#495057'
  },

  objectContentTitleEntity: {
    marginRight: '1rem',
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px'

    }
  },
  objectTextEntity: {
    '&:not(:last-child)': {
      marginRight: '0.5rem'
    },
    fontSize: {
      xs:'10px',
      sm: '12px',
      xl: '14px',
    },
  },
  contentTitle: {
    // paddingTop: '17px',
    paddingTop: '8px',
    fontSize: {
      // xs: '16px',
      // xl: '18px',
      xs: '10px',
      md: '12px',
      xl: '14px'
    },
    lineHeight: '0.85em',
    fontWeight: '700',
    fontFamily: 'typography.fontFamily',
    color: '#495057'
  },

  contentTitleInfoCard :{
    paddingTop: 0,
  },

  stackHolderTitle: {
    // paddingTop: 0,
    // fontSize:'24px',
    height: '2rem'

  },

  contentSubTitle: {
    marginLeft: '15px',
    paddingTop: '8px',
    fontSize: {
      xs: '10px',
      lg: '12px',
      xl: '14px'

    },
    lineHeight: '0.85em',
    fontWeight: '400',
    fontFamily: 'typography.fontFamily',
    color: '#495057'
  },
  nameHolder: {
    display: 'flex',
    flexDirection: 'column'

  },
  wrapper: {
    display: {
      xs: 'block',
      sm: 'flex',
      lg: 'block',
      xl: 'flex'
    },
    justifyContent: 'space-between',
    marginTop: '0.5rem'
  },
  infoRow: {
    display: 'flex'
  },

  infoHolder: {
    marginRight: '1rem'
  },

  link: {
    textDecoration: 'none',
    color: 'rgba(17,75,118,0.8)',
    '&:hover': {
      color: 'rgba(17,75,118,1.8)'
    }
  },
  buttonHolder: {
    // margin: '15px 0 10px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonLinkObjects: {
    // width: '130px',
    maxWidth: {
      xs: '6rem',
      sm:'7.5rem',
      xl:'8rem'
    },
    width: '100%',
    height: '34px',
    background: 'rgba(4, 98, 54, 0.8)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1.25em',
    fontSize: {
      xs: '10px',
      sm: '12px',
      lg: '13px',
      xl: '14px',
    },
    marginRight: {
      xs: '0.5rem',
      md:'1rem',
      xl:'1.2rem',
    },
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(4, 98, 54, 0.9)',
      borderColor: '#046236',
      boxShadow: 'none'
    }
  },
  buttonUnlinkObjects: {
    // width: '130px',
    maxWidth: {
      xs: '6rem',
      sm:'7.5rem',
      xl:'8rem'
    },
    width: '100%',
    height: '34px',
    background: '#D67575',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1.25em',
    fontSize: {
      xs: '10px',
      sm: '12px',
      lg: '13px',
      xl: '14px',
    },
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#d01f1f',
      borderColor: '#e53a3a',
      boxShadow: 'none'
    }
  },
  buttonEdit: {
    maxWidth: '95px',
    width: '100%',
    height: '34px',
    background: 'rgba(4, 98, 54, 0.8)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1.25em',
    fontSize: '14px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(4, 98, 54, 0.9)',
      borderColor: '#046236',
      boxShadow: 'none'
    }
  },
  buttonDelete: {
    marginLeft: '10px',
    // width: '95px',
    // height: '34px',
    maxWidth: '95px',
    width: '100%',
    background: '#D67575',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.25em',
    fontSize: '14px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#d01f1f',
      borderColor: '#e53a3a',
      boxShadow: 'none'
    }
  },
  buttonCansel: {
    marginLeft: '10px',
    width: '95px',
    height: '34px',
    background: 'rgba(17,75,118,0.8)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1.25em',
    fontSize: '14px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(17,75,118,0.9)',
      borderColor: '#114B76',
      boxShadow: 'none'
    }
  },
  buttonEdit2: {
    marginLeft: '10px',
    width: '110px',
    height: '40px',
    background: 'rgba(17,75,118,0.8)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1.25em',
    fontSize: '14px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(17,75,118,0.7)',
      borderColor: '#114B76',
      boxShadow: 'none'
    }
  },
  editorArea: {
    // height: '162px',
    // maxHeight: '286px',
    maxWidth: '650px',
    overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }
  },
  userInfo: {
    margin: '0.5rem 0',
    fontSize: {
      xs: '10px',
      md: '12px',
      xl: '14px'
    }
  },
  editButtonGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stakeholderName: {
    fontSize: {
      xs: '10px',
      md: '12px',
      xl: '14px'
    },
    lineHeight: '1.20em',
    fontWeight: '700',
    fontFamily: 'typography.fontFamily',
    color: '#495057'
  },

  cardContentStakeholders: {
    height: { lg:'5rem' },
    overflowY: 'auto',
    paddingTop: 0,
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }
  },

  ownersHolder: {
    marginBottom: '15px'
  },

  firstIconHolder: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  secondIconHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  relatedInfoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  colorAgo: {
    color: '#d01f1f',
    fontSize: {
      xs: '10px',
      md: '12px',
      xl: '14px'
    }
  },
  colorIn: {
    color: '#495057',
    fontSize: {
      xs: '10px',
      md: '12px',
      xl: '14px'
    }
  },
  objectTable: {
    marginTop: '15px',
    // maxHeight: 460,
    overflowX: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }
  },
  imgKadLink: {
    height: 20,
    width: 20,
    marginLeft: 4,
    '&:hover': {
      outline: '4px solid rgba(0,0,0,0.2)'
    }
  },
  linkedInIcon: {
    color: '#0A66C2'
  },
  facebookIcon: {
    color: '#1877f2'
  },

  stackHolderContainer: {
    width: '100%',
    height: '100%'
  },
  firstColumnHolder: {
    // marginLeft: "1.5em",
    width: '100%',
    // maxHeight: '50vh',
    height: '100%',
    padding: '5px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0 auto',
    overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }
  },
  secondColumnHolder: {
    width: '100%',
    // maxHeight: '36vh',
    // height: '100%',
    padding: '5px 15px',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'flex-start',
    // flex: '1 0 auto',
    // overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }
  },
  columnItemsHolderBox: {
    display: {
      xs: 'block',
      md: 'flex'
    },
    // justifyContent: "space-between",
    width: '100%'
  },
  objectsHolderBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginTop: '17px',
    // marginBottom: '15px',
    marginTop: '12px',
    marginBottom: '10px'

  },
  grid: {
    // minHeight:"87vh",
    maxHeight: { lg: '87vh' },
    // height: '100%',
    overflowY: { lg: 'auto' },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px'
    }

  },
  divider: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  },
  gridMargin: {
    marginBottom: {
      xs: 0,
      lg: '0.5rem',
    },
  },

  tableCell: {
    padding: '0px 6px'

  },
  mainTableHead: {
    background: '#B4CEE5',
  },
  collapseTableHead: {
    background: 'rgba(180, 206, 229, 0.3)'
  },
  icon: {
    margin: '0 auto'
  },
  relInfoEditIcon: {
    color: '#B4CEE5',
    fontSize: {
      xs: 'medium',
      sm: 'medium',
      md: '24px'
    }
  },
  boxInfoWindow: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textInfoWindow: {
    fontSize: '12px',
    fontWeight: '700',
    marginRight: '1rem'
  }
};

export default styles;
